@import "../variables.css";

#language-toggler {
    position: fixed;
    top: 0.5em;
    right: 0.5em;
    z-index: 15;
}

@media (max-width: 700px) {
    #language-toggler.in-competences-overview {
        top: auto;
        bottom: calc(100% - var(--menu-bar-total-height) + 0.5em);
        right: auto;
        left: 0.5em;
    }
}
