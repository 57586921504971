#about {
    max-width: 600px;
    width: 50%;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
}

#about p {
    text-align: left;
}

@media (max-width: 700px) {
    #about {
        margin-left: var(--spacing-double);
        margin-right: var(--spacing-double);
        width: auto;
    }
}