@import "variables.css";

html {
    background-color: var(--main-bg-color);
}

body {
    font-family: var(--main-font);
}

h1, h2, h3, h4, h5, h6 {
    font-family: var(--title-font);
}

a {
    color: var(--link-color);
}

@media (prefers-reduced-motion: no-preference) {
    .page {
        animation: var(--fade-in);
    }

    .fade-out {
        animation: var(--fade-out) !important;
    }
}

.App {
    text-align: center;
}

.App-header {
    color: white;
}
