@import "../variables.css";

.quote {
    padding-bottom: var(--spacing-single);
    margin-bottom: var(--spacing-double);
    border-bottom: 1px solid var(--main-txt-color);
    font-style: italic;
}

.quote-text {
    font-weight: bold;
    margin-bottom: var(--spacing-half);
}

.quote-author {
    font-weight: lighter;
}
