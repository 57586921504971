@import '../variables.css';

.header-menu {
    width: 100%;
    height: var(--menu-bar-basic-height);
    position: fixed;
    top: 0;
    z-index: 10;
    padding-top: var(--spacing-single);
    padding-bottom: var(--spacing-single);
}

.logo-container {
    height: 100%;
    width: 30%;
    max-width: var(--navigation-max-width);
    text-align: center;
}

.body {
    position: relative;
    display: flex;
    margin-top: var(--menu-bar-total-height);
    text-align: left;
}

.body img {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.navigation {
    position: fixed;
}

.navigation-menu-space {
    width: 30%;
    max-width: var(--navigation-max-width);
}

.content {
    flex: 1 0 0;
    margin-left: 15%;
    margin-right: 15%;
    margin-bottom: 10%;
}

.content h1 {
    text-align: center;
}

.logo {
    background-color: Transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

/* Mobile burger menu */

.header-menu .mobile-burger-container {
    background-color: Transparent;
    border: none;
    outline: none;
    position: fixed;
    display: none;
    right: 0;
    height: var(--menu-bar-basic-height);
    width: var(--menu-bar-basic-height);
    cursor: pointer;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}
.header-menu .mobile-burger-container #mobile-burger {
    width: 18px;
    margin: -4px auto 0;
}
.header-menu .mobile-burger-container #mobile-burger .bar {
    width: 100%;
    height: 2px;
    display: block;
    position: relative;
    background: #FFF;
    transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
    transition-delay: 0s;
}
.header-menu .mobile-burger-container #mobile-burger .bar.topBar {
    -webkit-transform: translateY(0px) rotate(0deg);
    transform: translateY(0px) rotate(0deg);
}
.header-menu .mobile-burger-container #mobile-burger .bar.btmBar {
    -webkit-transform: translateY(6px) rotate(0deg);
    transform: translateY(6px) rotate(0deg);
}
.header-menu .mobile-burger-container.menu-opened {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}
.header-menu .mobile-burger-container.menu-opened #mobile-burger .bar {
    transition: all 0.4s cubic-bezier(0.4, 0.01, 0.165, 0.99);
    transition-delay: 0.2s;
}
.header-menu .mobile-burger-container.menu-opened #mobile-burger .bar.topBar {
    -webkit-transform: translateY(2px) rotate(45deg);
    transform: translateY(2px) rotate(45deg);
}
.header-menu .mobile-burger-container.menu-opened #mobile-burger .bar.btmBar {
    -webkit-transform: translateY(0px) rotate(-45deg);
    transform: translateY(0px) rotate(-45deg);
}

/* Media rules */

@media (max-width: 700px) {
    .header-menu {
        padding-left: 0;
        background-color: var(--header-bg-color);
    }

    .logo-container {
        display: inline-block;
        width: 100%;
    }

    .navigation-menu-space {
        display: none;
    }

    .content {
        margin-left: var(--spacing-double);
        margin-right: var(--spacing-double);
        width: auto;
    }

    .header-menu .mobile-burger-container {
        display: inline-block;
    }
}

@media (prefers-reduced-motion: no-preference) {
    .content {
        animation: var(--fade-in);
    }
}
