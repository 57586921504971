@import "../variables.css";

.navigation {
    position: fixed;
    width: 30%;
    max-width: var(--navigation-max-width);
}

span {
    font-family: var(--main-font) !important;
    font-weight: bold;
}

ul, .list-item {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

@media (max-width: 700px) {
    .navigation {
        background-color: var(--mobile-menu-bg-color);
        width: 100%;
        height: 100%;
        max-width: 100%;
        top: -100%;
        overflow-y: scroll;
        transition: top var(--transition-time-short);
    }

    .navigation.menu-opened {
        top: 0;
    }
}
