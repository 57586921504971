:root {
    /* Fonts */
    --title-font: 'VT323', Ubuntu, sans-serif;
    --main-font: Ubuntu, sans-serif;

    /* Colors */
    --main-bg-color: #282c34;
    --main-txt-color: white;
    --header-bg-color: #050706;
    --mobile-menu-bg-color: rgba(0, 0, 0, 0.85);
    --link-color: DeepSkyBlue;

    /* Sizes */
    --spacing-half: 8px;
    --spacing-single: 16px;
    --spacing-double: 32px;
    --menu-bar-basic-height: 60px;
    --menu-bar-total-height: 92px;
    --navigation-max-width: 300px;

    /* Times */
    --transition-time-short: 0.3s;

    /* Animation */
    --fade-in: fade-in-animation 1 var(--transition-time-short) linear;
    --fade-out: fade-out-animation 1 var(--transition-time-short) linear;
}

@keyframes fade-in-animation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fade-out-animation {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
