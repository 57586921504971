@import "../variables.css";

.nav {
    width: 100%;
    height: 15vh !important;
    background-color: transparent !important;
}

.icon {
    color: var(--main-txt-color) !important;
}

.label {
    font-size: 1.5rem !important;
    font-family: var(--title-font) !important;
}

@media (max-width: 500px) {
    .label {
        font-size: 1.2rem !important;
    }
}
